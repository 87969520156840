import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi,`}</h1>
    <p>{`I'm Sawit. I previously graduated from the University of Michigan with a degree in computer science. Currently, I'm a software engineer at `}<a parentName="p" {...{
        "href": "https://bandprotocol.com"
      }}>{`Band Protocol`}</a>{`, where I mainly work mostly in ReasonML and Go. In my spare time, I like to build cool and (occasionally) useful `}<a parentName="p" {...{
        "href": "/projects"
      }}>{`tools`}</a>{`, `}<a parentName="p" {...{
        "href": "/blog"
      }}>{`write`}</a>{` and read `}<a parentName="p" {...{
        "href": "https://www.goodreads.com/user/show/56154926-sawit-trisirisatayawong"
      }}>{`books`}</a>{` or other things that `}<a parentName="p" {...{
        "href": "/about#interests"
      }}>{`interests me`}</a>{`, play piano and badminton, and `}<a parentName="p" {...{
        "href": "https://instagram.com/tansawit"
      }}>{`take photos`}</a>{`. To learn more about me, see `}<a parentName="p" {...{
        "href": "/about"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      